import { graphql, PageProps } from 'gatsby';
import React, { useEffect, useState } from 'react';

import { Flex, Stack } from '../../components/Core';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Text from '../../components/Text';
import { TEXT_STYLES } from '../../components/Text/constants';
import { INavigationProps } from '../../types/wordpress/blocks';
import { slugifyForKinequick } from './utils/kinequick';
import Select from '../../components/Form/Select';
import { __, __html } from '../../utils/helpers/i18n';
import SectionWithTitle from '../../components/SectionWithTitle';
import ServiceContactForm from '../../components/ServiceContactForm';
import { formatSelectOptions, syncWith } from '../../utils/options';

interface LinkForRdv {
  url: string;
  target: string;
  place: {
    slug: string;
  };
  service: {
    slug: string;
  };
}

type Configurations = Queries.PageKinequickQuery['configurations']['nodes'];

const getIFrameURL = (
  configurations: Configurations[],
  place: string,
  service: string
): string | boolean => {
  if (!configurations || configurations.length <= 0) return false;

  if (!service || !place) return false;

  const links = (configurations[0] as any)?.configuration
    .linksForRdv as LinkForRdv[];

  if (!links) return false;

  const iframeLink = links.find((link: any) => {
    return link?.place?.slug === place && link?.service?.slug === service;
  });

  if (iframeLink?.target == '_blank' && iframeLink?.url) {
    // Open in new tab
    const newTab = window.open(iframeLink.url, '_blank');

    // Check if the new tab was successfully opened
    if (newTab) {
      // Focus on the new tab
      newTab.focus();
    } else {
      // If the new tab was blocked by a pop-up blocker, open in the current tab
      window.location.href = iframeLink.url;
    }

    return false;
  }

  if (iframeLink?.target == 'iframe' && iframeLink?.url) return iframeLink.url;

  return false;
};

// type IKinequickCentresData = Queries.PageKinequickQuery['allWpCentre']['nodes'][number];

type IKinequickTemplate = PageProps<
  Queries.PageKinequickQuery,
  IPageContext,
  Pick<INavigationProps, 'idCentre' | 'kinequickSpeciality' | 'kinequickType'>
>;

const getDefaultCentreId = ({
  navigationProp,
}: {
  navigationProp: string | undefined;
}) => {
  return navigationProp || null;
};

const getDefaultKinequickParams = ({
  navigationProps,
}: {
  navigationProps: IKinequickTemplate['location']['state'];
}) => {
  const defaultCentreId = getDefaultCentreId({
    navigationProp: navigationProps?.idCentre,
  });

  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);

  const serializedDefaultCentre = slugifyForKinequick(
    urlParams.get('centre') ?? defaultCentreId ?? null
  );

  const serializedDefaultSpeciality = slugifyForKinequick(
    urlParams.get('speciality') ?? navigationProps?.kinequickSpeciality
  );

  const serializedDefaultType = slugifyForKinequick(
    urlParams.get('type') ?? navigationProps?.kinequickType
  );

  return {
    location: serializedDefaultCentre,
    speciality: serializedDefaultSpeciality,
    type: serializedDefaultType,
  };
};

const KineQuickPageTemplate: FCWithClassName<IKinequickTemplate> = ({
  data,
  pageContext,
  location,
}) => {
  const pageData = data.wpPage;
  const pageTemplateData =
    'contenuKinequick' in data.wpPage.template
      ? data.wpPage.template.contenuKinequick
      : null;

  if (
    !pageData ||
    !pageData?.link ||
    !pageContext.defaultLanguage ||
    !pageTemplateData
  )
    return null;

  const navigationProps = location.state;

  const [kinequickLocation, setKinequickLocation] = useState<
    undefined | string
  >(undefined);
  const [kinequickSpeciality, setKinequickSpeciality] = useState<
    undefined | string
  >(undefined);
  const [kinequickType, setKinequickType] = useState(undefined);

  const centres = formatSelectOptions(
    syncWith(
      data.configurations.nodes[0]?.configuration?.linksForRdv as any,
      kinequickSpeciality,
      'service'
    ),
    'place'
  );
  const services = formatSelectOptions(
    syncWith(
      data.configurations.nodes[0]?.configuration?.linksForRdv as any,
      kinequickLocation,
      'place'
    ),
    'service'
  );

  useEffect(() => {
    const defaultKinequickParams = getDefaultKinequickParams({
      navigationProps,
    });

    setKinequickLocation(defaultKinequickParams.location);
    setKinequickSpeciality(defaultKinequickParams.speciality);
    setKinequickType(defaultKinequickParams.type);
  }, []);

  useEffect(() => {
    // add kinequick location to url params
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);

    if (kinequickLocation) {
      urlParams.set('centre', kinequickLocation);
    } else {
      urlParams.delete('centre');
    }

    if (kinequickSpeciality) {
      urlParams.set('speciality', kinequickSpeciality);
    } else {
      urlParams.delete('speciality');
    }

    if (kinequickType) {
      urlParams.set('type', kinequickType);
    } else {
      urlParams.delete('type');
    }

    url.search = urlParams.toString();
    window.history.pushState({}, '', url);
  }, [kinequickLocation, kinequickSpeciality]);

  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Flex
        align={{ base: 'flex-start', sm: 'flex-end' }}
        direction={{ base: 'column', sm: 'row' }}
        className="w-full max-w-3xl gap-4 mx-auto my-12"
      >
        <Stack className="w-full">
          <Text className="mb-2 text-left">
            {__('Choisissez un centre :', pageContext.currentLanguage.code)}
          </Text>

          <Select
            className="text-base"
            id="center"
            type="select"
            name="center"
            defaultValue={kinequickLocation}
            value={kinequickLocation}
            onChange={(e) => {
              setKinequickLocation(e.target.value);
            }}
            options={centres?.map((center) => {
              if (!center) return undefined;

              return {
                checked: false,
                label: center.title,
                name: center?.slug,
              };
            })}
            placeholder={__(
              'Choisissez un centre',
              pageContext.currentLanguage.code
            )}
          />
        </Stack>

        {services?.length > 0 && (
          <Stack className="w-full">
            <Text className="mb-2 text-left">
              {__('Choisissez un service :', pageContext.currentLanguage.code)}
            </Text>

            <Select
              className="w-full text-base"
              id="service"
              type="select"
              name="service"
              defaultValue={kinequickSpeciality}
              value={kinequickSpeciality}
              onChange={(e) => {
                setKinequickSpeciality(e.target.value);
              }}
              options={services?.map((service) => {
                if (!service) return undefined;

                return {
                  checked: false,
                  label: service.title,
                  name: service?.slug,
                };
              })}
              placeholder={__(
                'Choisissez un service',
                pageContext.currentLanguage.code
              )}
            />
          </Stack>
        )}
      </Flex>

      <Stack className="space-y-8">
        {getIFrameURL(
          data.configurations.nodes as any,
          kinequickLocation,
          kinequickSpeciality
        ) ? (
          <Stack className="-mx-4 lg:-mx-10">
            <iframe
              src={
                getIFrameURL(
                  data.configurations.nodes as any,
                  kinequickLocation,
                  kinequickSpeciality
                ) as string
              }
              className="w-full min-h-[80vh]"
            />
          </Stack>
        ) : kinequickLocation && kinequickSpeciality ? (
          <>
            <Text variant={TEXT_STYLES.P} className="self-center text-center">
              {__html(
                `Ce service n'est pas disponible à la réservation en ligne.<br />Merci de remplir le formulaire de réservation ci-dessous, ou bien de nous joindre par téléphone au <a href="tel:+32470826146">+32 470 82 61 46</a> (tous les jours de 9h à 18h). Merci.`,
                pageContext.currentLanguage.code
              )}
              {/* {__(
                `Ce service n'est pas disponible à la réservation en ligne, pourriez-vous svp nous fournir plus d'information via le formulaire de contact ci-dessous pour répondre au mieux à votre demande. Merci`,
                pageContext.currentLanguage.code
              )} */}
            </Text>
            <SectionWithTitle
              id="appointment"
              heading={__(
                'Prendre rendez-vous',
                pageContext.currentLanguage.code
              )}
            >
              <ServiceContactForm
                languageCode={pageContext.currentLanguage.code as 'EN' | 'FR'}
                centres={centres as any}
                serviceTitle={kinequickSpeciality}
                defaultCentre={kinequickLocation}
                serviceFrenchSlug={kinequickSpeciality}
              />
            </SectionWithTitle>
          </>
        ) : (
          <Text variant={TEXT_STYLES.H5} className="self-center text-center">
            {__(
              'Veuillez sélectionner un centre et un service',
              pageContext.currentLanguage.code
            )}
          </Text>
        )}
      </Stack>
    </Layout>
  );
};

export default KineQuickPageTemplate;

export const query = graphql`
  query PageKinequick($id: String, $currentLanguageSlug: String) {
    wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_PageKinequick {
          contenuKinequick {
            messageCentreFilter
            messageAlternative
          }
        }
      }
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
    }
    configurations: allWpConfiguration(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        configuration {
          linksForRdv {
            target
            url
            service {
              ... on WpService {
                title
                slug
              }
            }
            place {
              ... on WpCentre {
                title
                slug
              }
            }
          }
        }
      }
    }
  }
`;
